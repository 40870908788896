<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <clinicSelector />
    </v-container>
    <v-container fluid class="smy_contain">
      <v-row dense no-gutters class="table-header">
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0" cols="6">
          <h2>Users</h2>
        </v-col>
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0 d-flex justify-end" cols="6">
          <div style="width: 300px; display:inline-block">
            <v-text-field
              v-model="search"
              append-icon="$riwIconSet_search"
              label="Search"
              single-line
              hide-details
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <v-btn class="ml-4" depressed outlined color="primary" @click="inviteNewUser" :disabled="!this.currentClinic">Invite User</v-btn>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col class="pb-0 mb-0 mt-0 pt-0" cols="12">
          <v-card class="flat">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loadingUserTable"
            >
              <template v-slot:top>
                <v-dialog v-model="edit_dialog" max-width="500px" scrollable persistent>
                  <v-form @submit.prevent>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ editTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 100%; overflow-y: auto;">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field dense v-model="editedItem.emailAddress" label="Email Address" outlined disabled></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            Permissions:
                            <template v-for="permission in availablePermissions">
                              <v-checkbox color="primary" :key="permission" v-model="editedItem.permissions" :label="permission" :value="permission"></v-checkbox>
                            </template>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <div v-if="edit_processing">
                        Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeEditDialog" :disabled="edit_processing">Cancel</v-btn>
                      <v-btn color="green accent-3" text @click="save" :disabled="edit_processing" type="submit">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                  </v-form>
                </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="!(item.userId === $store.getters.idToken) || ($store.getters.isGlobalAdmin || $store.getters.isSysAdmin)"
                  small
                  class='ml-2'
                  title="Edit"
                  @click="editItem(item)"
                >
                  $riwIconSet_edit
                </v-icon>
              </template>
              <template v-slot:[`item.permissions`]="{ item }">
                {{ item.permissions.join(", ") }}
              </template>
            </v-data-table>
            <v-dialog v-model="new_dialog" max-width="500px" scrollable persistent>
              <v-form v-model="email_valid" @submit.prevent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ newTitle }}</span>
                </v-card-title>

                <v-card-text style="height: 100%; overflow-y: auto;">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                          <v-text-field dense v-model="newUser.emailAddress" label="Email Address" outlined :rules="[rules.required, rules.email]" :disabled="newUser.inviteSelf" autofocus autocomplete="email"></v-text-field>
                        <v-checkbox v-if="$store.getters.isGlobalAdmin || $store.getters.isSysAdmin" v-model="newUser.inviteSelf" label="Invite Self" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        Initial permission to grant:
                        <v-radio-group v-model="newUser.permissions[0]" :mandatory="true">
                          <template v-for="permission in availablePermissions">
                            <v-radio :key="permission" :label="permission" :value="permission"></v-radio>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div v-if="new_processing">
                    Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeNewDialog" :disabled="new_processing">Cancel</v-btn>
                  <v-btn color="green accent-3" text @click="sendNew" :disabled="!email_valid || new_processing" type="submit">Invite</v-btn>
                </v-card-actions>
              </v-card>
              </v-form>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

import adminUsersViewModel from '@/view-models/admin-users-view-model';

export default adminUsersViewModel;

</script>

