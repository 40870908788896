import apiEndpoints from '@/models/common/api-endpoints';
import clinicSelector from '@/components/ClinicSelector';
import generalFunctions from '@/models/common/general-functions';
import { mapGetters } from 'vuex';

export default {
  name: 'admin-users',
  components: {
    clinicSelector
  },
  data () {
    return {
      editTitle: 'Edit User',
      newTitle: 'Invite User',
      edit_dialog: false,
      email_valid: false,
      new_dialog: false,
      newUser: {
        emailAddress: 'None',
        permissions: []
      },
      editedItem: {},
      originalItem: {},
      search: undefined,
      loadingUserTable: false,
      headers: [
        {
          text: 'Email Address',
          align: 'start',
          sortable: false,
          value: 'emailAddress'
        },
        { text: 'Permissions', value: 'permissions' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      items: undefined,
      new_processing: false,
      edit_processing: false,
      availablePermissions: [
        'ClinicUser',
        'ClinicAdmin'
      ],
      rules: {
        required: value => !!value || 'A value is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid email address entered';
        }
      },
      globalClinicList: undefined,
      currentGlobalClinic: undefined

    };
  },

  /***********
   * Methods *
   ***********/

  methods: {
    populateClinics: async function () {
      // early out for if we are on the normal admin route
      if (this.$route.meta.requiresClinic) {
        return;
      }

      // populate the global clinic list
      const response = await apiEndpoints.getClinicsForCurrentAPI();
      
      if (response.result) {
        this.globalClinicList = response.data;
        
        // Set the initial clinic
        this.currentGlobalClinic = this.globalClinicList[0];
      }

    },
    defaultNewUser () {
      const defaultUser = {
        emailAddress: '',
        permissions: [this.availablePermissions[0]],
        inviteSelf: false
      };

      return defaultUser;
    },
    inviteNewUser () {
      this.newUser = this.defaultNewUser();

      this.new_dialog = true;
    },
    editItem (item) {
      // Save an in-progress version of the item to be edited
      this.editedItem = generalFunctions.deepCopy(item);

      // persist the original version of the item
      this.originalItem = generalFunctions.deepCopy(item);

      // Show the dialog
      this.edit_dialog = true;
    },
    hasPermission (permission) {
      if (this.editedItem.permissions.includes(permission)) {
        return true;
      }
      return false;
    },
    retrieveUsers: async function () {
      // early out if there is no currentClinic
      if (!this.currentClinic) {
        return;
      }
      // set the loading state and clear the current items data
      this.loadingUserTable = true;
      this.items = undefined;

      // retrieve the users
      const response = await apiEndpoints.getPermissionsForClinic(this.currentClinic.clinicId);
      
      if (response.result) {
        this.items = response.data;
      }

      // Set the user table loading state to false
      this.loadingUserTable = false;
    },
    save: async function () {
      // do some validation
      if (this.editedItem.permissions.length < 1) {
        if (!confirm('Revoking all available permissions will cause the clinic and the user to lose visibility of each other. Would you like to continue?')) {
          this.closeEditDialog();
          return;
        }
      }

      // set processing state
      this.edit_processing = true;

      // persist the changes
      for (let element of this.availablePermissions) {
        // if the permission is present, but didn't used to be
        if (this.editedItem.permissions.includes(element) && !this.originalItem.permissions.includes(element)) {
          // Add the permission
          await apiEndpoints.addPermissionForClinic(this.editedItem.userId, element, this.currentClinic.clinicId);
        }

        // if the permission is not present, but used to be
        if (!this.editedItem.permissions.includes(element) && this.originalItem.permissions.includes(element)) {
          // Remove the permission
          await apiEndpoints.removePermissionForClinic(this.editedItem.userId, element, this.currentClinic.clinicId);
        }
      }

      // refresh the user list
      await this.retrieveUsers();

      // If the edit involved the currently logged in user, update the clinic and permissions data for the user
      if (this.editedItem.userId === this.idToken) {
        await this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
      }

      // close the dialog
      this.closeEditDialog();

      // set processing state
      this.edit_processing = false;
    },
    closeEditDialog () {
      this.edit_dialog = false;
    },
    closeNewDialog () {
      // Close the dialog
      this.new_dialog = false;
    },
    sendNew: async function () {
      // set processing state
      this.new_processing = true;

      // send the invitation
      const response = await apiEndpoints.inviteUserForClinic(this.newUser.emailAddress, this.newUser.permissions[0], this.currentClinic.clinicId);

      if (response.result) {
        // refresh the user list
        await this.retrieveUsers();

        // If inviteSelf was checked then refresh the permissions for the user
        if (this.newUser.inviteSelf) {
          await this.$store.dispatch('fetchAndStoreClinicAndPermissionsInfo');
        }
      }

      // Close the dialog
      this.closeNewDialog();

      // set processing state
      this.new_processing = false;
    }
  },
  mounted () {
    this.populateClinics();
    this.retrieveUsers();
  }, 

  /************
   * Computed *
   ************/

  computed: {
    ...mapGetters({
      currentClinicInStore: 'currentClinic',
      allClinics: 'allClinics',
      adminClinics: 'adminClinics',
      idToken: 'idToken',
      emailAddress: 'emailAddress'
    }),
    currentClinic: function () {
      if (this.$route.meta.requiresClinic) {
        return this.currentClinicInStore;
      } else {
        return this.currentGlobalClinic;
      }
    }
  },

  /*********
   * Watch *
   *********/

  watch: {
    currentClinic: function (newVal, oldVal) {
      this.retrieveUsers();
    },
    // adminClinics: function (newVal, oldVal) {
    //   this.retrieveUsers();
    // },
    // '$route.meta.requiresClinic': function (newVal, oldVal) {
    //   this.items = undefined;
    //   this.populateClinics();
    //   this.retrieveUsers();
    // },
    'newUser.inviteSelf': function (newVal, oldVal) {
      if (newVal) {
        this.newUser.emailAddress = this.emailAddress;
      } else {
        this.newUser.emailAddress = '';
      }
    }
  }
};